<template>
  <v-container>
    <v-navigation-drawer
      v-model="drawer"
      app
      clipped
      right
      color="blue-grey lighten-5"
      class="elevation-0"
      width="850"
      temporary
    >
      <!-- <contacto /> -->
      <!-- <reportar/> -->
    </v-navigation-drawer>
    <v-card>
      <v-toolbar
        dark
        dense
        color="teal"
      >
        <v-col cols="1">
          <v-avatar
            tile
            dense
            size="55"
            color="teal lighten-2"
            class="avatar rounded-lg"
          >
            <v-icon
              color="white"
              x-large
              class="mb-2"
            >
              mdi-card-account-details
            </v-icon>
          </v-avatar>
        </v-col>
        <h3>Contactos</h3>
        <v-spacer />
        <v-col cols="3">
          <v-text-field
            v-model="search"
            class="mx-2"
            solo-inverted
            dense
            color="red"
            append-icon="mdi-magnify"
            label="Filtrar"
            single-line
            hide-details
          />
        </v-col>
        <v-btn
          small
          class="ma-1"
          color="success"
          @click="consultar"
        >
          Consultar <v-icon>mdi-update</v-icon>
        </v-btn>
        <v-btn
          align="right"
          color="orange"
          dark
          small
          class="ma-1"
          @click.stop="contacto()"
        >
          Nuevo <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <!-- EXCEL -->
        <v-btn
          align="right"
          class="ma-4"
          small
          color="green"
          dark
        >
          <download-excel
            :data="contactos"
            :fields="json_fields"
            worksheet="My Worksheet"
            name="CatContactos.xls"
          >
            Excel
            <v-icon class="ma-1">
              mdi-download
            </v-icon>
          </download-excel>
        </v-btn>
      </v-toolbar>
    </v-card>

    <v-row>
      <v-col
        cols="12"
        md="12"
        xs="12"
      >
        <v-card elevation="10">
          <v-subheader class="">
            <h2 class="teal--text">
              Contactos
            </h2>
            <v-spacer />
          </v-subheader>

          <v-row>
            <v-col>
              <v-data-table
                dense
                heigth="400"
                :headers="headers"
                :items="contactos"
                :search="search"
              >
                <template v-slot:item="{ item }">
                  <tr @dblclick="editar(item)">
                    <td>{{ item.idcontacto }}</td>

                    <td>{{ item.nomcont }}</td>
                    <td>{{ item.empresa }}</td>
                    <td>{{ item.telefono }}</td>
                    <td>{{ item.email }}</td>
                    <td>{{ item.numcli }}</td>
                    <td>{{ item.celular }}</td>
                    <td>{{ item.sucursal }}</td>
                    <td>{{ item.departamento }}</td>
                  </tr>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
// import contacto from '@/views/crm/contactos/contacto'
export default {
  // components: {
  //   contacto
  // },
  data() {
    return {
      drawer: false,
      search: '',
      contactos: [],
      headers: [
        { text: 'Id', value: 'idcontacto' },

        { text: 'Nombre del contacto', value: 'nomcont' },
        { text: 'Empresa', value: 'empresa' },
        { text: 'Telefono', value: 'telefono' },
        { text: 'Email', value: 'email' },
        { text: 'Cliente', value: 'numcli' },
        { text: 'Celular', value: 'celular' },
        { text: 'Sucursal', value: 'sucursal' },
        { text: 'Departamento', value: 'departamento' }
      ],
      json_fields: {
        Id: 'idcontacto',
        Nombre: 'nomcont',
        Empresa: 'empresa',
        Telefono: 'telefono',
        Email: 'email',
        Cliente: 'numcli',
        Celular: 'celular',
        Sucursal: 'sucursal',
        Departamento: 'departamento'
      },
      json_meta: [
        [
          {
            key: 'charset',
            value: 'utf-8'
          }
        ]
      ],
      json_data: []
    }
  },
  created() {
    this.consultar()
  },

  methods: {
    ...mapActions('oportunidad', ['setOportunidad']),
    consultar() {
      // limpiar
      this.contactos = []
      // console.log(this.getdatosUsuario)
      // let id = this.getdatosUsuario.idusuariosweb
      // console.log('consultar', id)

      this.$http
        .get('v2/contactos.list')
        .then(response => {
          // console.log('response', response.data)
          if (response.data.error === null) {
            this.contactos = response.data.result
            // console.log('contactos', this.contactos)
          }
        })
        .catch(error => {
          console.log(error)
        })
    },

    editar(item) {
      console.log('Renglonitem', item)
      this.$router.push({ name: 'contacto', params: { contacto: item } })
    },

    contacto() {
      this.$router.push({ name: 'contacto' })

      // this.drawer = !this.drawer
    }
  }
}
</script>

<style scoped>
.avatar {
  top: -10px;
}
</style>
